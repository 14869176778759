import React from 'react'

import Avatar, { AvatarProps } from '@mui/material/Avatar'
import { TypographyVariant, styled, useTheme } from '@mui/material/styles'
import { svgIconClasses } from '@mui/material/SvgIcon'
import Typography from '@mui/material/Typography'

import { ProfileColour } from 'types/UserContext'
import getContrastText from 'utils/getContrastText'

export type AvatarSize = 'small' | 'large'

interface CIQAvatarProps extends Omit<AvatarProps, 'alt' | 'color'> {
  size?: AvatarSize // defaults to "small". Controls the size of the avatar as per designs
  badge?: boolean // defaults to false. Represents the green dot.
  firstName: string // required
  color?: ProfileColour
}

export const avatarSize: Record<AvatarSize, number> = {
  small: 24,
  large: 48,
}

export const StyledAvatar = styled(Avatar)<{ size: AvatarSize; color: ProfileColour }>(({ size, color }) => ({
  width: avatarSize[size],
  height: avatarSize[size],
  backgroundColor: color,

  [`& .${svgIconClasses.root}`]: {
    fontSize: avatarSize[size],
  },
}))

const variant: Record<AvatarSize, TypographyVariant> = {
  small: 'caption',
  large: 'h2',
}

export default function CIQAvatar({
  size = 'small',
  badge = false,
  firstName,
  color = '#0172B1',
  children,
  ...otherProps
}: CIQAvatarProps): JSX.Element {
  const firstLetter = firstName?.trim().split(' ').at(0)?.charAt(0).toUpperCase()
  const theme = useTheme()
  const renderLetterOrChildren = firstLetter ? (
    <Typography color={getContrastText(theme, color)} variant={variant[size]}>
      {firstLetter}
    </Typography>
  ) : (
    children
  )

  const avatarProps = {
    ...otherProps,
    ...(!!otherProps.src && { alt: firstName }),
  }

  return (
    <StyledAvatar size={size} color={color} aria-hidden {...avatarProps}>
      {renderLetterOrChildren}
    </StyledAvatar>
  )
}
