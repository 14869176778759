import { PaletteColours } from 'theme/redesign'
import { AdvisorUser } from 'types/Advisor'
import { Entity } from 'types/Negotiation'

export type UserContextResponse = {
  userId: string
  name: string
  firstName: string
  lastName: string
  emailAddress: string
  ipAddress: string
  role: keyof typeof UserRoles
  isSso: boolean
  signedInByIntegration: boolean
  account?: AccountContext
  subAccounts: SubAccountContext[]
  clientSubAccounts: ClientSubAccountContext[]
  userFlags: UserFlags
  enabledFeatures: string[]
  profileColour: ProfileColour
  availableActions: ContextAvailableActions
  unreadAnnouncementIds: string[]
}

export type UserContext = UserContextResponse & {
  // Inferred parameters (i.e. not from API)
  email?: string // Just a re-mapping of emailAddress
  subAccount: SubAccountContext & {
    clientAccountId?: ClientSubAccountContext['accountId']
    clientAccountName?: ClientSubAccountContext['accountName']
  }
}

export type ContextAvailableActions = {
  canManageAdvisorRelationships?: boolean
  canManageAdvisorUsers?: boolean
  canManageRelationshipNegotiations?: boolean
  canManageRelationshipPresets?: boolean
  canManageAPICredentials?: boolean
  canManageEntities?: boolean
  canManageSSO?: boolean
  canManageSubAccounts?: boolean
  canManageUsers?: boolean
  canViewAccountInformation?: boolean
  canViewReports?: boolean
  canViewNegotiationReports?: boolean
  canManageSuperManagers?: boolean
  canViewNegotiations?: boolean
}

export type ContextState = {
  userId: string
  name: string
  email?: string
  role: keyof typeof UserRoles | ''
  roleLabel: UserRoles | ''
  isSso: boolean
  isSupport?: boolean
  signedInByIntegration: boolean
  account?: AccountContext
  subAccounts: SubAccountContext[]
  userFlags: UserFlags
  clientSubAccounts: ClientSubAccountContext[]
  enabledFeatures: string[]
  profileColour: ProfileColour
  availableActions: ContextAvailableActions
  emailAddress: string
  firstName: string
  unreadAnnouncementIds: string[]
}

export type ProfileColour = PaletteColours

export type AdvisorRequests = {
  id: string
  clientAccountName: string
  subAccountsCount: number
}

export type AccountContext = {
  accountId: string
  accountName: string
  issues?: {
    unclaimedEntities: number
    advisorRequests: AdvisorRequests[]
  }
  isOneLink: boolean
  isAdvisor: boolean
}

export type SubAccountContext = {
  subAccountId: string
  subAccountName: string
  entities: Entity[]
  isIntegrationManaged: boolean
  integrationRedirectUri?: string
  clientAccountId?: string
  clientAccountName?: string
  statistics: {
    negotiationCount: number
    presetCount: number
    unreadNotifications: { [key: string]: number }
  }
  permittedNegotiations?: string[]
  permittedPublishers?: {
    id: string
    name: string
    description: string
    code: string
    defaultDisclaimer: {
      pack?: { value: string }
      document?: { header: string; body: string }
    }
  }[]
  users?: Pick<AdvisorUser, 'email' | 'firstName' | 'lastName' | 'id'>[]
}

export type ClientSubAccountContext = {
  accountId: string
  accountName: string
  subAccounts: SubAccountContext[]
}

export type SubAccountData = SubAccountContext | ClientSubAccountContext

export const isClientSubAccountData = (data: SubAccountData): data is ClientSubAccountContext => 'subAccounts' in data

export type UserFlags = {
  displayIntroduction: boolean
  displayIntroductionCounter: number
}

export enum UserRoles {
  super_manager = 'Super Manager',
  manager = 'Manager',
  nakhoda_super_manager = 'Nakhoda Super Manager',
  nakhoda_manager = 'Nakhoda Manager',
  nakhoda_digitiser = 'Nakhoda Digitiser',
  nakhoda_document_service = 'Nakhoda Document Service',
  nakhoda_dev = 'Nakhoda Developer',
  nakhoda_read_only = 'Nakhoda Read Only',
  nakhoda_reports_service = 'Nakhoda Reports Service',
  nakhoda_support = 'Nakhoda Support',
  admin = 'Admin',
  auditor = 'Auditor',
  approver = 'Approver',
  editor = 'Editor',
  read_only_manager = 'Read Only Manager (API)',
  read_only_user_manager = 'Read Only Manager',
  advisory_company = 'Advisory Company',
  one_link_editor = 'Guest Editor',
}
